import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

// Define options for rendering nodes
const renderOptions = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <h1 className="text-zinc-50 text-5xl sm:text-6xl font-bold mb-8 leading-tights">{children}</h1>,
    [BLOCKS.HEADING_2]: (node, children) => <h2 className="text-zinc-50 text-3xl sm:text-5xl font-semibold mb-4">{children}</h2>,
    [BLOCKS.HEADING_3]: (node, children) => <h3 className="text-zinc-50 text-4xl font-semibold mb-3">{children}</h3>,
    [BLOCKS.HEADING_4]: (node, children) => <h4 className="text-zinc-50 text-3xl font-medium mb-2">{children}</h4>,
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="text-zinc-50 text-2xl sm:text-3xl md:text-3xl mb-5 lg:text-4xl">{children}</p>,
    [INLINES.HYPERLINK]: (node, children) => (
      <a
        href={node.data.uri}
        target="_blank"
        rel="noopener noreferrer"
        className="text-zinc-50 underline hover:text-burgundy transition-colors"
        style={{ zIndex: 9999 }}
      >
        {children}
      </a>
    ),
    [BLOCKS.HR]: () => <hr className="my-6 border-gray-300" />,
    [INLINES.ITALIC]: (node, children) => <em className="italic">{children}</em>,
  },
};

const RichTextComponent = ({ richText, inverted }) => {
  if (!richText) {
    return null;
  }

  let parsedContent;

  try {
    parsedContent = JSON.parse(richText);
  } catch (error) {
    console.error('Failed to parse rich text content:', error);
    return <p>Error loading content.</p>;
  }

  return <div className={`${inverted ? 'text-zinc-50' : ''}`}>{documentToReactComponents(parsedContent, renderOptions)}</div>;
};

export default RichTextComponent;

import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import { isMobile } from 'react-device-detect'
import moment from 'moment';

export const SlideImageFull = (props) => {
  return (
    <div
      className={`${props.objectFit === 'contain' ? 'pb-0' : ''
        } w-full h-full absolute text-white flex items-center justify-center flex-col`}
      style={{
        backgroundColor: props.bgColor || 'transparent',
      }}
    >
      {props.image && (
        <GatsbyImage
          image={props.image}
          className={`absolute top-0 left-0 w-full h-full min-h-full ${props.objectFit === 'contain' ? '' : ''
            }`}
          imgClassname={'w-full max-w-full'}
          objectFit={props.objectFit || 'cover'}
          alt={''}
        />
      )}
      {props.caption &&
        <div className={'bottom-3 sm:bottom-[0.6rem] left-3 sm:left-2 bg-white absolute bg-opacity-80 hover:bg-opacity-100 px-3 py-1 inline-flex items-center justify-center'}>
          <p className={'text-2xl h-8'} style={{ lineHeight: 1.4 }}>{props.caption}</p>
        </div>
      }
      <div className={'absolute top-0 left-0 w-full h-full z-50 flex items-center justify-center flex-col p-4'}>{props.children}</div>
    </div>
  )
}

export const SlideImageLeft = (props) => {
  return (
    <div className={`relative w-full h-screen-mobile sm:h-screen-desktop flex flex-col sm:grid sm:grid-cols-2 ${props.isArtistSlide ? 'bg-zinc-700' : ''}`}>
      <div className={'relative'}>
        <GatsbyImage
          image={props.image}
          className={'relative top-0 left-0 right-0 h-1/2 sm:w-full sm:h-full'}
          imgClassname={'w-full max-w-full'}
          objectFit={props.objectFit || 'cover'}
          style={{
            height: props.objectFit !== 'cover' ? 'calc(100% - 60px)' : '100%',
          }}
          alt={''}
        />
        {props.caption &&
          <div className={'bottom-16 left-2 bg-white absolute bg-opacity-80 z-50 hover:bg-opacity-100 px-3 py-1 flex items-center justify-center'}>
            <p className={'text-2xl h-8'} style={{
              lineHeight: 1.5
            }}>{props.caption}</p>
          </div>
        }
      </div>
      <div className={'flex justify-center items-center flex-col w-full h-screen-mobile sm:h-screen-desktop slide-richtext'}>
        {props.children}
      </div>
    </div>
  )
}

export const SlideImageRight = (props) => {
  return (
    <div className={`w-full h-screen-mobile sm:h-screen-desktop flex flex-col sm:grid sm:grid-cols-2 ${props.isArtistSlide ? 'bg-zinc-700' : ''}`}>
      <div className={'flex justify-center items-center flex-col w-full h-screen-mobile sm:h-screen-desktop slide-richtext'}>
        {props.children}
      </div>
      <div className={'relative'}>
        <GatsbyImage
          image={props.image}
          className={'relative top-0 left-0 h-1/2 sm:w-full sm:h-full'}
          imgClassname={'w-full max-w-full'}
          objectFit={props.objectFit || 'contain'}
          style={{
            height: props.objectFit !== 'cover' ? 'calc(100% - 60px)' : '100%',
          }}
          alt={''}
        />
        {props.caption &&
          <div className={'bottom-14 left-0 bg-white absolute bg-opacity-80 z-50 m-2 hover:bg-opacity-100 px-3 py-1 max-w-1/2 flex items-center justify-center'}>
            <p className={'text-2xl h-8'} style={{ lineHeight: 1.5 }}>{props.caption}</p>
          </div>
        }
      </div>
    </div>
  )
}

export const InvertedParagraph = (props) => {
  return (
    <div className={`text-zinc-50 inherit-color inherit-font inline-block pointer-events-none ${props.ellipses ? 'm-0 font-bold text-3xl' : ''}`} style={{ zIndex: 9999, marginTop: '1rem' }}>
      {props.children}
    </div>
  )
}

export const InvertedTitle = (props) => {
  return (
    <h2 className={'text-zinc-50 inline-block pointer-events-none font-title'} style={{ zIndex: 9999 }}>
      {props.children}
    </h2>
  )
}

export const InvertedTitleMain = (props) => {
  return (
    <h3 className={'text-zinc-50 inline-block pointer-events-none text-4xl sm:text-5xl font-title font-bold m-0'} style={{ zIndex: 999 }}>
      {props.children}
    </h3>
  )
}

export const InvertedTitleSans = (props) => {
  return (
    <h3 className={'text-zinc-50 inline-block pointer-events-none text-2xl sm:text-4xl font-sans font-bold m-0'} style={{ zIndex: 999 }}>
      {props.children}
    </h3>
  )
}

export const SlideContent = (props) => {
  const [mobile, setMobile] = useState(isMobile);

  useEffect(() => {
    setMobile(isMobile);
  }, [isMobile]);

  return (
    <div
      className={`relative w-full m-0 mb-0 sm:mb-10 flex sm:items-start justify-center flex-col p-5 pt-7 sm:p-10 flow ${props.className}`}
      style={{
        maxWidth: '100%',
        minHeight: '-webkit-fill-available',
        // height: mobile ? '100vh' : '100%',
        height: '100%',
        paddingBottom: mobile ? '70px' : '2rem',
      }}
    >
      <div className={'flex flex-col gap-8 justify-start sm:justify-start sm:pt-16 h-full mt-0 w-full'}>
        <div className={'flex gap-5 items-end bg-zinc-700 p-3 sm:hidden'}>
          <GatsbyImage image={props.image} imgClassName={`max-w-1/2`} className={'w-1/3 aspect-[4/3] object-cover'} alt={''} objectFit='cover' />
          <div className={'relative w-1/2 h-full flex flex-col justify-start items-start gap-2'} style={{ zIndex: 9999 }}>
            <div className={'py-2 flex flex-col gap-2'}>
              {props.date && props.showDate &&
                <div>
                  <span className={'font-bold text-zinc-500'}>Date</span>
                  <p className={'text-zinc-300 text-xl'}>
                    {moment(props.date).format('D MMMM YYYY')}
                  </p>
                </div>
              }
              {props.credit &&
                <div>
                  <span className={'font-bold text-zinc-500'}>Credit</span>
                  <p className={'text-zinc-300 text-xl'}>
                    {props.credit}
                  </p>
                </div>
              }
            </div>
            {/* {props.imageUrl &&
              <p className={'absolute top-0 right-0 w-full text-right text-zinc-100 z-50'}>
                <a
                  style={{ zIndex: 9999 }}
                  href={props.imageUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={'bg-zinc-500 text-xl sm:text-2xl py-1 px-2 hover:bg-burgundy hover:text-zinc-50 font-bold transition-colors absolute right-0 z-50'}
                >
                  Download image</a>
              </p>
            } */}
          </div>
        </div>
        <div className={'slide-richtext'}>
          {props.children}
        </div>
        {props.date && props.showDate &&
          <div className={'hidden sm:flex flex-col gap-1 absolute bottom-8 left-5'}>
            <span className={'font-bold text-zinc-500'}>Date</span>
            <p className={'text-zinc-300 text-2xl'}>
              {moment(props.date).format('D MMMM YYYY')}
            </p>
          </div>
        }
      </div>
    </div >
  )
}

export const SlideIntro = (props) => {
  const { title, images, introText, objectFit, bgColor } = props;

  const [shuffledImages, setShuffledImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to shuffle images randomly
  const shuffleImages = (images) => {
    return images
      .map((img) => ({ img, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ img }) => img);
  };

  // On component mount, shuffle images
  useEffect(() => {
    if (images && images.length > 0) {
      setShuffledImages(shuffleImages(images));
    }
  }, [images]);

  // Image loop: go to next image after a delay
  useEffect(() => {
    if (shuffledImages.length > 0) {
      const timer = setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % shuffledImages.length);
      }, 5000); // 5-second duration per slide
      return () => clearTimeout(timer);
    }
  }, [shuffledImages, currentIndex]);

  return (
    <div
      className={`${objectFit === 'contain' ? 'pb-0' : ''
        } w-full h-full absolute text-white flex items-center justify-center flex-col`}
      style={{ backgroundColor: bgColor || 'transparent' }}
    >
      {/* Animated Images */}
      <div className="absolute top-0 left-0 w-full h-full">
        <AnimatePresence>
          {shuffledImages.length > 0 && (
            <motion.div
              key={currentIndex} // Ensures each image gets a unique key for animation
              className="absolute top-0 left-0 w-full h-full"
              initial={{ opacity: 0, scale: 1 }}
              animate={{ opacity: 1, scale: 1.05 }}
              exit={{ opacity: 0, scale: 1.2 }}
              transition={{ duration: 3, ease: 'easeInOut' }}
            >
              <GatsbyImage
                image={shuffledImages[currentIndex]}
                className="w-full h-full"
                imgClassName="w-full h-full"
                objectFit={objectFit || 'cover'}
                alt={`Slide ${currentIndex + 1}`}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Text Overlay */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5, delay: 0.75 }}
        className="absolute top-0 left-0 w-full h-full text-white flex items-center justify-center flex-col z-50"
      >
        <div className="block px-12 py-7 bg-white text-zinc-900 shadow-xl text-center mx-5">
          <h1 className="mb-4">{title}</h1>
          <p className="font-title-regular">{introText}</p>
          <hr className="mt-8 mb-3" />
          <p className="pt-5 opacity-70 text-center">
            Use the <strong>← Back</strong> and <strong>Next →</strong> buttons to navigate through the story.
          </p>
        </div>
      </motion.div>
    </div>
  );
};

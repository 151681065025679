if (process.env.NODE_ENV === "development") {
  const originalConsoleError = console.error;
  console.error = (...args) => {
    if (
      typeof args[0] === "string" &&
      args[0].includes("Support for defaultProps will be removed")
    ) {
      return; // Suppress this warning
    }
    originalConsoleError(...args);
  };
}

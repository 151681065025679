"use client"

import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Waypoint } from 'react-waypoint'

/* Importing custom components from main dir */
import Layout from 'components/common/layout'
import ScrollMap from 'components/scrollytelling/map/scrollMapAmphan'
import HeaderComponent from 'components/scrollytelling/header'
import ScrollImage from 'components/scrollytelling/scrollImage'
import ContainerComponent from 'components/scrollytelling/container'
import TextContainerComponent from 'components/scrollytelling/text'
import AuthorInfoComponent from 'components/scrollytelling/authorInfo'

import cycloneData from 'data/bangladesh-story/amphan-geojson.js'
import khulnaData from 'data/bangladesh-story/khulna-geojson.js'
import bangladeshFloodData from 'data/bangladesh-story/bangladesh-flood-geojson.js'
import faniData from 'data/bangladesh-story/fani-geojson.js'
import bulbulData from 'data/bangladesh-story/bulbul-geojson.js'

const ScrollToTop = styled.a`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 999;
  bottom: 0;

  &:hover {
    background: transparent !important;
  }

  button {
    position: relative;
    background-size: cover;
    background-image: url('https://www.thenewhumanitarian.org/sites/all/themes/thenewhumanitarian/images/icon-return.svg');
  }
`

const StyledTags = styled.ul`
  margin-left: 0;

  .meta-list__item {
    margin-top: 0.5rem;
  }
  .meta-list__item[itemProp='reportType'],
  .meta-list__item[itemProp='reportType'] a:hover {
    &:hover {
      background: transparent;
      color: black;
    }
  }
  .meta-list__item[itemProp='datePublished'] {
    &:hover {
      cursor: default;
    }
  }
  .meta-list__item:first-child:hover {
    opacity: 0.9;
  }
  .meta-list__item:first-child a:hover {
    color: white;
    opacity: 0.9;
  }
  @media screen and (max-width: 500px) {
    padding: 0;
    margin: 0 1rem;
  }
`

const DateLine = styled.span`
  @media screen and (max-width: 500px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const BangladeshStory = (props) => {
  // eslint-disable-next-line
  const [mapTransition, setMapTransition] = useState({
    duration: 2000,
  })

  const coordinates = {
    start: {
      lat: 19.798684333721866,
      lng: 89.42873013971807,
      zoom: 4,
    },
    khulna: {
      lat: 22.815774,
      lng: 89.568679,
      zoom: 4,
    },
    koyra: {
      lat: 22.35192051117547,
      lng: 89.28567409310857,
      zoom: 10,
    },
    assasuni: {
      lat: 22.512610018873758,
      lng: 89.18831439535361,
      zoom: 10,
    },
  }

  const [mapPosition, setMapPosition] = useState(coordinates.start)
  const [showAmphan, setShowAmphan] = useState(false)
  const [showKhulna, setShowKhulna] = useState(false)
  const [showBangladeshFlood, setShowBangladeshFlood] = useState(false)
  const [showFani, setShowFani] = useState(false)
  const [showBulbul, setShowBulbul] = useState(false)

  const Images = props.data.allFile.edges

  const getImageByName = (name) => {
    return Images.filter((el) => el.node.name === name)[0].node.childImageSharp
      .gatsbyImageData
  }

  return (
    <Layout
      shareProps={{
        title: 'The New Humanitarian | Repeat disasters multiply climate risks on Bangladesh’s damaged coast',
        url: 'https://interactive.thenewhumanitarian.org/stories/2020/bangladesh-amphan-hidden-climate-costs/',
        socialTitle: 'Bangladesh’s hidden climate costs',
        socialDescription:
          'Damages multiply, months after a string of disasters. Lost income puts more pressure on women and girls, and pushes some families to migrate.',
        socialImage:
          'https://assets.thenewhumanitarian.org/s3fs-public/styles/responsive_large/public/legacy_s3_root/azhc_1614.jpg.webp?itok=l-qIq4iM',
        nodeId: 261814,
        updatedTime: '2020-12-09T09:24:19+00:00',
        modiefiedTime: '2020-12-09T09:24:19+00:00',
        publishedTime: '2020-12-09T09:19:37+00:00',
      }}
    >
      <a href='#start'>
        <HeaderComponent
          image={getImageByName('aDJI_0914')}
          title=''
          scrollAnimation
        />
      </a>

      <ContainerComponent
        style={{ paddingBottom: 0, maxWidth: '900px', margin: '0 auto' }}
      >
        <div
          style={{ height: 0, marginTop: '-23rem', paddingTop: '5rem' }}
          id='start'
        >
          &nbsp;
        </div>
        <TextContainerComponent>
          <StyledTags className='meta-list' style={{ padding: '0 ' }}>
            <li className='meta-list__item'>
              <a
                href='https://www.thenewhumanitarian.org/environment-and-disasters'
                title='View more reports on Environment and Disasters'
                target={'_blank'}
                rel={'noreferrer'}
                className='meta-list__item--theme tab tab--report-theme'
                data-report-theme='Environment and Disasters'
              >
                Environment and Disasters
              </a>
            </li>
            <li className='meta-list__item' itemProp='reportType'>
              <a
                target='_blank'
                rel='noreferrer noopener'
                href='https://www.thenewhumanitarian.org/photo-feature'
                title='View more reports on Photo feature'
                className='meta-list__item--type'
              >
                Photo feature
              </a>
            </li>
            <li
              className='meta-list__item'
              itemProp='datePublished'
              content='2020-09-15T12:21:29+01:00'
            >
              9 December 2020
            </li>
          </StyledTags>
          <h1 style={{ paddingTop: '1rem', paddingBottom: 0 }}>
            Bangladesh’s hidden climate costs
          </h1>
          <h3 style={{ paddingTop: '1rem' }}>
            ‘We are not able to build our houses again.’
          </h3>
          <AuthorInfoComponent />
        </TextContainerComponent>
      </ContainerComponent>

      <ContainerComponent
        style={{
          paddingBottom: 0,
          // paddingTop: '2rem',
          maxWidth: '70rem',
          margin: '0 auto',
        }}
      >
        <TextContainerComponent>
          {/* TODO Dateline must be modular */}
          <DateLine
            className='article__author-location'
            style={{
              // paddingTop: '2rem',
              textTransform: 'none',
            }}
          >
            KHULNA, Bangladesh
          </DateLine>
          <div
            className={'content__bulk field-name-body'}
            style={{
              padding: 0,
            }}
          >
            <p>
              Coastal Bangladesh was lashed by a punishing storm this year, then
              submerged by monsoon floods. The damages linger long after.
            </p>
            <p>
              Cyclone Amphan tore across Bangladesh’s southwestern coast in May,
              and severe floods inundated a quarter of the country weeks later.
            </p>
            <p>
              Photographer Zakir Hossain Chowdhury’s images show how the damage
              endures. Battered communities in parts of Khulna district, a strip
              of coastal wetlands, are still living in temporary homes on
              embankments, or in emergency storm shelters meant to house people
              for days – not weeks or months.
            </p>
            <p>
              Amphan smashed open kilometres of embankments protecting
              sub-districts like Koyra and Assasuni. The barriers, which keep
              tidal waters at bay, haven’t been repaired, leaving communities
              exposed to daily floods that rush in with each high tide.
            </p>
            <p>
              The fallout goes beyond the physical damage. A chain of disasters
              – including a{' '}
              <a
                target='_blank'
                rel='noreferrer noopener'
                href='https://www.thenewhumanitarian.org/analysis/2019/07/04/india-mass-cyclone-evacuations-saved-lives-not-livelihoods'
              >
                pair
              </a>{' '}
              of{' '}
              <a
                target='_blank'
                rel='noreferrer noopener'
                href='https://www.thenewhumanitarian.org/news/2019/11/28/Bangladesh-Bhasan-Char-Rohingya-island-camp'
              >
                storms
              </a>{' '}
              last year – have drained income and shattered livelihoods. Child
              marriage is on the rise as families struggle to cope, and as
              schools remain closed due to the coronavirus pandemic. Tired of
              rebuilding, some families are deciding to leave. This is a{' '}
              <a
                target='_blank'
                rel='noreferrer noopener'
                href='https://www.thenewhumanitarian.org/2019/03/05/how-dire-climate-displacement-warnings-are-becoming-reality-bangladesh'
              >
                migration pattern that has been repeated across the country
              </a>
              , each time disasters chip away at income or destroy homes and
              fields.
            </p>
            <p>
              Climate change is supercharging extreme weather, adding to the
              already ample risks people on the coast have lived with for
              generations. Researchers say global heating makes high-intensity
              storms like Amphan{' '}
              <a
                target='_blank'
                rel='noreferrer noopener'
                href='https://www.researchgate.net/publication/324993610_Impact_of_Climate_Change_on_Tropical_Cyclones_Frequency_and_Intensity_on_Indian_Coasts'
              >
                more common
              </a>{' '}
              and more damaging, adding to heavier rains, sea-level rise,
              accelerated coastal erosion, and other climate change impacts.
            </p>
            <p>
              Syed Sheikh, 42, isn’t willing to live with the risk any longer.
              Months after Amphan hit, he decided to pack up his life on the
              coast, moving further inland to a town where he hoped his family
              would be safer.
            </p>
            <p>
              He is tired of the constant rebuilding. “Our house was damaged for
              the eighth time in my life,” he said.
            </p>
          </div>
        </TextContainerComponent>
      </ContainerComponent>

      <ContainerComponent>
        <ScrollMap
          location={mapPosition}
          transition={mapTransition}
          cycloneData={cycloneData}
          showAmphan={showAmphan}
          khulnaData={khulnaData}
          showKhulna={showKhulna}
          bangladeshFlood={bangladeshFloodData}
          showBangladeshFlood={showBangladeshFlood}
          faniData={faniData}
          showFani={showFani}
          bulbulData={bulbulData}
          showBulbul={showBulbul}
        >
          <div className='step'>
            <Waypoint
              onEnter={() => {
                setMapPosition(coordinates.start)
                // setShowAmphan(false)
                // setShowKhulna(false)
                setShowBangladeshFlood(false)
              }}
            />
            <p>
              The Bay of Bengal is a basin for some of the world’s deadliest
              tropical cyclones. Storm risks usually peak in May and November,
              bookending a monsoon season that routinely floods millions in
              South Asia.
            </p>
          </div>
          <div className='step'>
            <Waypoint
              onEnter={() => {
                //setMapPosition(coordinates.khulna)}
                setShowAmphan(true)
                setShowKhulna(false)
                setShowBangladeshFlood(false)
              }}
            />
            <p>
              Cyclone Amphan formed in May, careening through India’s eastern
              coast and striking Bangladesh. The storm caused widespread damage
              in India’s Odisha and West Bengal states before crashing across
              the border into Bangladesh.
            </p>
          </div>
          <div className='step'>
            <Waypoint
              onEnter={() => {
                setMapPosition({ ...coordinates.khulna, zoom: 6 })
                setShowKhulna(true)
                setShowBangladeshFlood(false)
              }}
            />
            <p>
              More than 200,000 homes were destroyed or damaged in Bangladesh.{' '}
              <a
                target='_blank'
                rel='noreferrer noopener'
                href='https://reliefweb.int/report/bangladesh/bangladesh-cyclone-final-report-early-action-eap2018bd01'
              >
                Three quarters
              </a>{' '}
              of families in the hardest-hit areas, including{' '}
              <span style={{ color: '#a03c50', fontWeight: 700 }}>Khulna</span>,
              reported their food stocks were wiped out or drained.
            </p>
          </div>
          <div className='step'>
            <Waypoint
              onEnter={() => {
                setMapPosition({ ...coordinates.khulna, zoom: 6 })
                setShowKhulna(false)
                setShowBulbul(false)
                setShowFani(false)
                setShowBangladeshFlood(true)
              }}
            />
            {/* <p>
							<GatsbyImage image={getImageByName('aZHC_1709')} />
						</p> */}
            <p>
              Amphan was followed by severe monsoon floods across South Asia,
              which inundated northern Bangladesh and swept southward. The
              floods set in unusually early in June, and lasted longer than{' '}
              <a
                target='_blank'
                rel='noreferrer noopener'
                href='https://reliefweb.int/report/bangladesh/hctt-monsoon-flood-humanitarian-response-plan-monitoring-dashboard-30-november'
              >
                two months
              </a>{' '}
              in some areas. At the peak of flooding in July and August, at
              least a quarter of the country was submerged, including parts of
              Khulna district still reeling from Amphan.
            </p>
          </div>
          <div className='step'>
            <Waypoint
              onEnter={() => {
                setMapPosition({ ...coordinates.khulna, zoom: 5 })
                setShowBulbul(true)
                setShowFani(true)
                setShowAmphan(false)
                setShowBangladeshFlood(false)
              }}
            />
          </div>
          <div className='step'>
            <Waypoint
              onEnter={() => {
                setShowKhulna(true)
                setShowBulbul(true)
                setShowFani(true)
                setShowAmphan(false)
              }}
            />
            <p>
              This year’s disasters followed two 2019 storms,{' '}
              <span style={{ color: '#2db487', fontWeight: 700 }}>Fani</span>{' '}
              and{' '}
              <span style={{ color: '#f0781e', fontWeight: 700 }}>Bulbul</span>,
              which carved a path through the same coastal region. In recent
              years, countries like Bangladesh and India have minimised disaster
              casualties through extensive{' '}
              <a
                target='_blank'
                rel='noreferrer noopener'
                href='https://www.thenewhumanitarian.org/news/2017/09/05/flood-prone-south-asia-early-warning-systems-buy-precious-time'
              >
                early warning and evacuation systems
              </a>{' '}
              – storms in 1970 and 1991 are estimated to have killed hundreds of
              thousands. But the damage from consecutive{' '}
              <a
                target='_blank'
                rel='noreferrer noopener'
                href='https://reliefweb.int/report/bangladesh/bangladesh-cyclone-final-report-early-action-eap2018bd01'
              >
                disasters builds
              </a>
              , destabilising communities over the longer term.
            </p>
          </div>

          <div className='step'>
            <Waypoint
              onEnter={() => {
                setMapPosition(coordinates.start)
              }}
            />
          </div>
        </ScrollMap>

        <TextContainerComponent>
          <h2>Higher ground</h2>
          <p>
            Amphan destroyed at least 76 kilometres of coastal and river
            embankments, according to{' '}
            <a
              target='_blank'
              rel='noreferrer noopener'
              href='https://reliefweb.int/report/bangladesh/bangladesh-cyclone-final-report-early-action-eap2018bd01'
            >
              estimates
            </a>{' '}
            by the Bangladesh Red Crescent Society.
          </p>
          <p>
            Wide swathes of land remain submerged during daily high tides.
            Roadsides and smaller embankments have become narrow islands of
            refuge, where families and livestock crowd together.
          </p>
        </TextContainerComponent>

        <ScrollImage image={getImageByName('aFile041')}>
          <div className='step'>
            <p>
              An aerial view shows how families have set up homes on narrow
              embankments, which usually separate aquaculture shrimp farms, in
              southern Bangladesh’s Assasuni sub-district.
            </p>
          </div>
          <div className='step'>
            <p>
              Shunoy Sarker, 26, said he and some 120 families have sheltered on
              embankments like these since Amphan hit in May. “For long periods,
              we are living with saltwater,” he said, describing how saltwater
              rushes in during high tides. “We don’t have any income.”
            </p>
          </div>
        </ScrollImage>

        <TextContainerComponent>
          <h2>Displaced beside their homes</h2>
          <p>
            Amphan damaged or destroyed some{' '}
            <a
              target='_blank'
              rel='noreferrer noopener'
              href='https://www.dhakatribune.com/bangladesh/nation/2020/05/21/cyclone-amphan-83-000-homes-destroyed-in-khulna'
            >
              83,000 homes
            </a>{' '}
            in Khulna district, and storm surges – tidal waves that often
            accompany tropical cyclones – washed away embankments.
          </p>
        </TextContainerComponent>

        <ScrollImage image={getImageByName('aZHC_9890')}>
          <div className='step'>
            <p>
              Onita Rani and her neighbour walk in front of their damaged
              houses.
            </p>
          </div>
          <div className='step'>
            <p>
              Many made homeless by Amphan still live nearby: Their homes may
              still be standing, but the constant flooding makes them
              uninhabitable.
            </p>
          </div>
        </ScrollImage>

        {/* <TextContainerComponent>
					<h2>Volunteer clinics</h2>
					<p>Carmen Bardalez, 54, checks on a patient, Mateo Barbaran, 56. Bardalez is one of the Shipibo staff at the Comando Matico clinic in the Shipibo-Konibo community of San Francisco, 20 kilometres from Pucallpa. Barbaran was in a serious condition when he arrived in the clinic, but was still in pain here, unable to sit up for too long. Bardalez said many patients arrive already in a critical state, and don’t survive. On 28 July, the day TNH visited, three patients died here.</p>
					<p>The group operates a second clinic in Yarinacocha, a town near Pucallpa where many Shipibo have migrated to. It had nine volunteers working at the start of the pandemic, but is now staffed by over 25 people. Both clinics are funded exclusively by private donations.</p>
					<p>Not far from the clinic in Yarinacocha was an empty public health centre. It appeared to be barely operational, and TNH was told that consultations with a doctor were only possible every other week.</p>
				</TextContainerComponent> */}

        {/* <ScrollImage image={getImageByName('aZHC_1614')} minHeight="275vh" /> */}

        <TextContainerComponent>
          <h2>Daily floods</h2>
          <p>
            With coastal embankments destroyed and damaged, the floods return at
            high tide each day.
          </p>
        </TextContainerComponent>

        <ScrollImage image={getImageByName('aZHC_0502')}>
          <div className='step'>
            <p>
              Sarder Abul Kashem, 62, sits in his family’s mud-covered home.
            </p>
          </div>
          <div className='step'>
            <p>
              “When the tide begins, saltwater enters my house and then I sit up
              with my goats on my bed,” he said. “We are leading life worse than
              beasts.”
            </p>
          </div>
        </ScrollImage>

        <TextContainerComponent>
          <h2>Hidden risks</h2>
          <p>
            BRAC, the Bangladeshi aid group, says child marriage is{' '}
            <a
              target='_blank'
              rel='noreferrer noopener'
              href='https://bracusa.org/press-release-2020-10-11/'
            >
              on the rise
            </a>{' '}
            during the coronavirus pandemic as economic pressures build. These
            pressures also grow after disasters, including Amphan and the
            nationwide{' '}
            <a
              target='_blank'
              rel='noreferrer noopener'
              href='https://www.humanitarianresponse.info/en/operations/bangladesh/document/hctt-monsoon-flood-humanitarian-response-plan-monitoring-dashboard-30'
            >
              floods
            </a>
            .
          </p>
          <p>
            Some stranded families in Khulna report that child marriage has
            become more common.
          </p>
          <p>
            Shopna, 14, said many of her friends are now married: “During this
            coronavirus period, most of my classmates got married and I am alone
            now,” she said.
          </p>
        </TextContainerComponent>

        <ScrollImage image={getImageByName('aZHC_1760')}>
          <div className='step'>
            <p>
              Children study and play inside a cyclone shelter, where their
              families have lived since Cyclone Amphan struck in May.
            </p>
          </div>
        </ScrollImage>

        <ScrollImage image={getImageByName('aZHC_1709')}>
          <div className='step'>
            <p>
              Coastal Bangladesh’s network of storm shelters – sturdier,
              concrete buildings on higher ground – are meant for emergency use,
              but they have become longer-term housing for some families.
            </p>
          </div>
          <div className='step'>
            <p>
              “Our houses are submerged in tidal waters,” said Kulsum Ara Banu,
              68, who is also living in a shelter. “We are poor, and we are not
              able to build our houses again.”
            </p>
          </div>
        </ScrollImage>

        <TextContainerComponent>
          <h2>Rebuilding</h2>
          <p>
            Residents say they need embankments to be repaired in order to start
            rebuilding their own homes. Some of this is underway. Local
            governments are reinforcing damaged barriers with sandbags. However,
            the material is not permanent.
          </p>
        </TextContainerComponent>

        <ScrollImage image={getImageByName('aZHC_1088')} minHeight='150vh' />

        <ScrollImage image={getImageByName('aZHC_1361')}>
          <div className='step'>
            <p>
              People repair a damaged embankment using sandbags in Assasuni
              sub-district.
            </p>
          </div>
        </ScrollImage>

        <TextContainerComponent>
          <h2>Impacts multiply</h2>
          <p>
            The longer coastal areas stay flooded, the greater the disaster
            risks become. Saltwater inundation worsens inland{' '}
            <a
              target='_blank'
              rel='noreferrer noopener'
              href='https://www.thenewhumanitarian.org/Bangladesh-river-erosion-engulfs-homes-climate-change-migration'
            >
              erosion
            </a>
            , which already destroys thousands of homes each year in Bangladesh.
            It also makes crops harder to grow – a threat to long-term food
            security.
          </p>
        </TextContainerComponent>

        <ScrollImage image={getImageByName('aZHC_1614')}>
          <div className='step'>
            <p>
              A man collects bricks from his house, which is submerged by daily
              tides.
            </p>
          </div>
          <div className='step'>
            <p>
              “Our embankment collapsed and our houses have eroded,” said
              Habibur Rahman Sarder, 56. “We have no space to live, no space to
              cook food, and no place to grow food.”
            </p>
          </div>
        </ScrollImage>

        <TextContainerComponent>
          <p style={{ display: 'none' }}>&nbsp;</p>
          <p className={'text-center py-8'}>zhc/il/ag</p>
        </TextContainerComponent>

        <ScrollToTop href='#start'>
          <button className='js-return-to-top site-footer__return-button'>
            Return to top
          </button>
        </ScrollToTop>
      </ContainerComponent>
    </Layout>
  )
}

export const query = graphql`
  query Img {
    allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        extension: { in: ["jpg", "png", "jpeg"] }
        relativePath: { glob: "stories/2020/bangladesh-amphan-climate/*" }
      }
    ) {
      edges {
        node {
          extension
          dir
          modifiedTime
          name
          relativePath
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              width: 2000
              quality: 85
              layout: FULL_WIDTH
            )
          }
        }
      }
    }
  }
`

export default BangladeshStory

import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import { getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby'
import { motion } from 'framer-motion'
import RichTextComponent from '@components/scrollytelling/photo-slideshow/richtext'

import Layout from '@components/common/layout'
import '@styles/libya-art-piece.scss'

import { SlideImageFull, SlideImageLeft, SlideImageRight, SlideContent, SlideIntro, InvertedTitleMain, InvertedTitle, InvertedParagraph, InvertedTitleSans } from '@components/scrollytelling/photo-slideshow/index.js'

import { isMobile } from 'react-device-detect'

const Stories = loadable(() => import('react-insta-stories'))

const YearInPhotos = (props) => {
  const [mobile, setIsMobile] = useState(isMobile)
  const [isPaused, setIsPaused] = useState(true)
  const [currentSlide, setCurrentSlide] = useState(0)

  const photoStory = props.data.contentfulPhotoStory
  const seo = props.data.contentfulPhotoStory.seo

  const scrollIntoViewWithOffset = (selector, offset) => {
    window.scrollTo({
      behavior: 'smooth',
      top:
        document.querySelector(selector).getBoundingClientRect().top -
        document.body.getBoundingClientRect().top - offset
    })
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'ArrowRight') {
        changeSlide('next');
      } else if (event.key === 'ArrowLeft') {
        changeSlide('prev');
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    // Cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [currentSlide]); // Include currentSlide in the dependency array

  const changeSlide = (direction) => {
    scrollIntoViewWithOffset('#stories', mobile ? 60 : 0)

    if (direction === 'next') {
      if (currentSlide + 1 >= StoriesArray.length) {
        setCurrentSlide(0)
      } else {
        setCurrentSlide(currentSlide + 1)
      }
    } else {
      if (currentSlide - 1 < 0) {
        setCurrentSlide(StoriesArray.length - 1)
      } else {
        setCurrentSlide(currentSlide - 1)
      }
    }

    // console.log('Current Slide:', currentSlide)
    // console.log(photoStory.photos[currentSlide])
  }

  useEffect(() => {
    setIsMobile(isMobile)
  }, [isMobile])


  let isImageLeft = true; // Toggle flag for alternating slides

  // Generate StoriesMobile
  const StoriesMobile = photoStory.photos.flatMap((photo) => {
    const imageData = getImage(photo.image?.gatsbyImageData);
    const imageUrl = photo.image?.file?.url;

    // Fullscreen image slide
    const fullScreenSlide = {
      title: photo.title || null,
      showTitle: photo.showTitle,
      showDate: photo.showDate,
      content: (storyProps) => {
        storyProps.action('pause');

        return (
          <SlideImageFull
            image={imageData}
            objectFit="contain"
            caption={photo.credit || null}
          />
        );
      },
    };

    // Text-only slide
    const textSlide = {
      title: photo.title || null,
      showTitle: photo.showTitle,
      showDate: photo.showDate,
      content: (storyProps) => {
        storyProps.action('pause');

        return (
          <SlideContent image={imageData} credit={photo.credit || null} showDate={photo.showDate || null} date={photo.dateTime || null} imageUrl={imageUrl}>
            <RichTextComponent richText={photo.slideContent?.raw} />
          </SlideContent>
        );
      },
    };

    return [fullScreenSlide, textSlide];
  });

  const StoriesDesktop = photoStory.photos.flatMap((photo, index) => {
    // Convert gatsbyImageData into a usable image format
    const imageData = getImage(photo.image?.gatsbyImageData);
    const imageUrl = photo.image?.file?.url;

    // Full screen slide
    const fullScreenSlide = {
      title: photo.title || null,
      showTitle: photo.showTitle,
      showDate: photo.showDate,
      content: (storyProps) => {
        storyProps.action('pause');

        return (
          <SlideImageFull
            image={imageData}
            objectFit={'contain'}
            caption={photo.credit || null}
          />
        );
      },
    };

    // Determine whether to use SlideImageLeft or SlideImageRight
    const SlideComponent = isImageLeft ? SlideImageLeft : SlideImageRight;

    // Toggle the flag for the next slide
    isImageLeft = !isImageLeft;

    // Alternating slide
    const alternatingSlide = {
      title: photo.title || null,
      showTitle: photo.showTitle,
      showDate: photo.showDate,
      content: (storyProps) => {
        storyProps.action('pause');

        return (
          <SlideComponent
            image={imageData}
            objectFit={'cover'}
            caption={photo.credit || null}
          >
            <SlideContent image={imageData} credit={photo.credit || null} showDate={photo.showDate || null} date={photo.dateTime || null} imageUrl={imageUrl}>
              <div className="w-full">
                <RichTextComponent richText={photo.slideContent?.raw} />
              </div>
            </SlideContent>
          </SlideComponent>
        );
      },
    };

    // Combine the full-screen slide with the alternating slide
    return [fullScreenSlide, alternatingSlide];
  });

  const StoriesMobileWithIntro = [
    {
      title: null,
      content: (storyProps) => {
        storyProps.action('pause');

        return (
          <SlideIntro
            title={photoStory?.title}
            introText={photoStory?.introText}
            images={photoStory.photos.map(photo => getImage(photo.image?.gatsbyImageData))}
          />
        );
      },
    },
    ...StoriesMobile,
  ]

  const StoriesDesktopWithIntro = [
    {
      title: null,
      content: (storyProps) => {
        storyProps.action('pause');

        return (
          <SlideIntro
            title={photoStory?.title}
            introText={photoStory?.introText}
            images={photoStory.photos.map(photo => getImage(photo.image?.gatsbyImageData))}
          />
        );
      },
    },
    ...StoriesDesktop,
  ]

  const StoriesArray = isMobile ? StoriesMobileWithIntro : StoriesDesktopWithIntro

  return (
    <Layout
      shareProps={{
        title: `The New Humanitarian | ${seo.pageTitle}`,
        url: seo.seoUrl,
        socialTitle: seo.seoTitle,
        socialDescription: seo.seoDescription,
        socialImage: `https:${seo.seoImage?.file?.url}`,
        nodeId: seo.nodeId,
        updatedTime: seo.publicationDate,
        modiefiedTime: seo.publicationDate,
        publishedTime: seo.publicationDate
      }}
    >
      <div id='start' className={'libya-art-wrapper'}>
        <div
          className={
            'relative max-w-full h-screen-ios h-screen-mobile sm:h-screen-desktop sm:max-h-screen-desktop overflow-hidden flex items-center justify-center'
          }
          style={{ background: '#282828' }}
          id={'stories'}
        >
          <Stories
            stories={StoriesArray}
            loop
            isPaused={isPaused}
            width={'100%'}
            height={'100%'}
            storyContainerStyles={{
              background: '#282828',
              width: '100%',
              maxHeight: mobile ? 'calc(100% - 56px)' : 'calc(100% - 67px)',
              position: 'absolute',
              top: 0
            }}
            storyStyles={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            currentIndex={currentSlide}
          />


          <div
            className={
              `absolute grid ${StoriesArray[currentSlide]?.title && StoriesArray[currentSlide]?.showTitle ? 'grid-cols-3' : 'grid-cols-2'} bottom-0 left-0 right-0 w-full h-20 sm:h-24 bg-zinc-900 bg-opacity-50 sm:bg-gradient-to-r sm:from-zinc-900 sm:to-zinc-900 sm:via-transparent sm:bg-opacity-20 text-white px-3 z-50`
            }
            style={{ zIndex: 888 }}
          >
            {/* Progress Bar */}
            <div className={'h-3 absolute -top-3 left-0 bg-zinc-200 inline w-full z-30 bg-opacity-80'} />
            <motion.div
              animate={{
                width: currentSlide < 1 ? `2.5%` : `${(currentSlide / (StoriesArray.length - 1)) * 100}%`,
              }}
              transition={{
                duration: 0.15,
                ease: 'easeIn',
              }}
              className={'h-3 absolute -top-3 left-0 bg-burgundy inline w-full z-50'}
            />

            {/* Back Button (Grid Column 1) */}
            <button
              className={`px-3 py-1 font-bold cursor-pointer justify-self-start ${currentSlide === 0 ? 'opacity-30 pointer-events-none' : ''
                }`}
              onClick={() => changeSlide('back')}
            >
              ← Back
            </button>

            {/* Middle Section (Grid Column 2) */}
            {StoriesArray[currentSlide]?.title && StoriesArray[currentSlide]?.showTitle && (
              <div
                className={`
                  self-center justify-self-center px-4 py-3 font-bold border
                `}
                style={{ minWidth: '250px', maxWidth: '400px', textAlign: 'center' }}
              >
                <div class="bg-zinc-50 text-black inline-flex items-center justify-center gap-2 p-2 rounded-xs">
                  {/* Optional Icon */}
                  {!StoriesArray[currentSlide].title.includes('About') && (
                    <span className={'w-8 h-8 pb-1 inline-block'}>
                      <svg
                        width="100%"
                        height="auto"
                        viewBox="0 0 134 134"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M133.333 41.6665C133.333 37.0623 129.604 33.3331 125 33.3331H8.33333C3.7292 33.3331 0 37.0623 0 41.6665V125C0 129.604 3.7292 133.333 8.33333 133.333H125C129.604 133.333 133.333 129.604 133.333 125V41.6665ZM16.6667 116.667V91.6666L41.6667 66.6665L91.6667 116.667H16.6667ZM95.8333 79.1666C88.9323 79.1666 83.3333 73.5676 83.3333 66.6665C83.3333 59.7655 88.9323 54.1665 95.8333 54.1665C102.734 54.1665 108.333 59.7655 108.333 66.6665C108.333 73.5676 102.734 79.1666 95.8333 79.1666ZM43.9947 24.9998L58.724 10.2864C58.5626 9.65101 58.3334 9.03121 58.3334 8.33335C58.3334 3.72921 62.0626 0 66.6667 0C71.2708 0 75 3.72921 75 8.33335C75 9.00002 74.7709 9.57294 74.625 10.1875L89.4371 24.9995H77.5516L68.7912 16.2391C68.0985 16.4214 67.4162 16.6662 66.6661 16.6662C65.9317 16.6662 65.2806 16.437 64.614 16.2599L55.8744 24.9995L43.9947 24.9998Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  )}
                  <span className={`inline-block`}>
                    {StoriesArray[currentSlide].title}
                  </span>
                </div>
              </div>
            )}

            {/* Next Button (Grid Column 3) */}
            <button
              className={`px-3 py-1 font-bold cursor-pointer justify-self-end ${currentSlide >= StoriesArray.length ? 'opacity-30 pointer-events-none' : ''
                } ${currentSlide === 0 ? 'animate-pulse' : ''}`}
              onClick={() => changeSlide('next')}
            >
              {currentSlide + 1 >= StoriesArray.length && '← Restart'}
              {currentSlide > 0 && currentSlide + 1 < StoriesArray.length && 'Next →'}
              {currentSlide === 0 && 'Start →'}
            </button>
          </div>


          <div
            className={`absolute top-0 left-0 w-full h-full flex flex-row sm:grid sm:grid-cols-2 pointer-events-none`}
            style={{ zIndex: 999 }}
          >
            <div
              className={`w-full h-full cursor-pointer ${currentSlide === 0 ? 'opacity-30 pointer-events-none hidden' : 'pointer-events-auto'}`}
              onClick={() => {
                changeSlide('back')
              }}
            />
            <div
              className={`w-full h-full ${currentSlide === 0 ? 'sm:ml-full' : ''} ${currentSlide + 1 >= StoriesArray.length ? 'pointer-events-none cursor-default' : 'cursor-pointer pointer-events-auto'}`}
              onClick={() => {
                changeSlide('next')
              }}
            />
          </div>
        </div>

        {photoStory?.footerText &&
          <div className={'bg-zinc-900 w-full py-8 sm:py-24 relative flex flex-col justify-center items-center z-50'}>
            <div className={'px-8 sm:px-0 text-left inverted flow max-w-6xl'}>
              <RichTextComponent richText={photoStory.footerText?.raw} />
            </div>
          </div>
        }

      </div>
    </Layout >
  )
}

export default YearInPhotos

export const query = graphql`
  query YearInPhotos {
    contentfulPhotoStory(slug: { eq: "2024-in-photos" }) {
      title
      slug
      introText
      seo {
        pageTitle
        seoTitle
        seoDescription
        seoUrl
        nodeId
        seoImage {
          file {
            url
          }
        }
        publicationDate
      }
      footerText {
        raw
      }
      photos {
        id
        title
        dateTime
        showTitle
        showDate
        credit
        slideContent {
          raw
        }
        image {
          file {
              url
          }
          gatsbyImageData(
            width: 1024
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            layout: FULL_WIDTH
            quality: 80
          )
        }
      }
    }
  }
`;
